<template>
  <div class="about" :style="'height:'+(screeHeight)+'px;'">
    <van-search @click="goSearch" :readonly="true" placeholder="请输入搜索关键词" />
    <div class="category_ad" style="padding-top: 54px;">
      <van-swipe
        class="my-swipe"
        :autoplay="4000"
        duration="1000"
        indicator-color="white"
      >
        <van-swipe-item
          v-for="(item, index) in adsrcStr"
          :key="index"
          :style="'width:' + (screenWidth) + 'px;height:' + ((screenWidth) / 683 * 258) + 'px;'"
        >
          <van-image :src="item.thumb" lazy-load @click="bannerLink(index)">
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="about_body" :style="'height:'+(screeHeight-64-((screenWidth) / 683 * 258))+'px;'">
      <van-sidebar v-model="active" @change="sidebarChange" :style="'height:'+(screeHeight-114-((screenWidth) / 683 * 258))+'px;'" v-if="group && group.length>0">
        <van-sidebar-item v-for="item in group" :key="item.id" :title="item.name" />
      </van-sidebar>
      <div class="content" :style="'height:'+(screeHeight-64-((screenWidth) / 683 * 258))+'px;'">
        <div class="allgoods" v-if="active == 0 ? true : false">
          <van-grid :border="false" :column-num="3">
            <van-grid-item icon="bars" text="全部商品" @click="allGoods" />
          </van-grid>
        </div>
        <div class="content_list" v-if="active==0 ? false : true">
          <van-grid
            :border="false"
            :column-num="3"
            v-for="(item,index) in category"
            :key="index"
          >
            <van-cell
              :border="false"
              :title="item.name"
              value="查看全部"
              is-link
              :to="{ name: 'List', query: { groupid: item.id, shareid: shareid, merchid: merchid } }"
            />
            <van-grid-item
              class="van-ellipsis"
              v-for="(i,n) in item.children"
              :key="n"
              :text="i.name"
              @click="details(i.id,i.type)"
            >
              <template #icon>
                <van-image
                  width="85%"
                  :height="((screenWidth - 85) / 3 - 20) * 0.85 + 'px'"
                  radius="2"
                  fit="cover"
                  :src="i.thumb"
                  lazy-load
                >
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </template>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data(){
    return {
      active: 0, // 当前选中一级类
      groupid: 0, // 一级类id
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      categoryimg: require("../assets/01.png"), // 本地分类图片
      adsrcStr: [{
        thumb: require("../assets/ad.png")
      }], // 本地广告图片
      category:[], // 二级三级分类
      group: [], // 一级分类
      allgroup: [], // 全部分类
      shareid: 0, 
      merchid: 0,
    }
  },
  filters: {
    currencyFormat: function(num) {
      if (num === 0) {
        return "0.00";
      } else {
        num = num + "";
        if (num.length == 1) {
          num = "00" + num;
        }
        if (num.length == 2) {
          num = "0" + num;
        }
        let start = 0;
        start = num.length - 2;
        return num.slice(0, start) + "." + num.slice(start);
      }
    },
    dateFormat: function(value) {
      let date = null;
      let y = null;
      date = new Date(value);
      y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      /*
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      */
      return y + "/" + MM + "/" + d; // + ' ' + h + ':' + m + ':' + s;
    }
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    
    this.init();
  },
  activated() {
    this.$parent.getmerchname(this.merchid, "全部分类");
    /* 绑定用户和子商户会员关系 */
    if (this.merchid > 0) {
      this.setmerchMber();
    }
  },
  methods: {
    init() {
      this.getCategory(); // 获取一级分类
      this.getadvimg(); // 获取图片
    },
    // 获取一级分类
    getCategory(){
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      this.$axios
        .get(this.$store.state.domain + "web/category/get_onelist?list=1&merchid=" + _that.merchid)
        .then(function(response){
          console.log(response);
          if (response && response.data) {
            // _that.group = response.data
            const allgoods = {
              id: 0,
              name: "推荐分类",
              thumb: ""
            }
            _that.group.push(allgoods);
            response.data.forEach(item=>{
              _that.group.push(item);
            })
          }
        })
        .catch(function(error){
          console.log(error);
        })
    },
    // 一级类切换
    sidebarChange(index){
      this.groupid = parseInt(this.group[index].id);
      if (this.allgroup[index]) {
        this.category = this.allgroup[index];
      }else{
        this.getAllCategory();
      }
    },
    // 获取一级分类下面的所有分类
    getAllCategory(){
      let _that = null;
      _that = this;
      _that.$toast({
        type: "loading",
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
      });
      // _that.getadvimg();
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/category/get_nextAll",
          _that.$qs.stringify({
            id: _that.groupid,
            merchid: _that.merchid
          })
        )
        .then(function(response){
          console.log(response);
          _that.$toast.clear();
          if (response && response.data) {
            _that.allgroup[_that.active] = response.data
            _that.category = _that.allgroup[_that.active];
          }
        })
        .catch(function(error){
          console.log(error);
        })
    },
    //  跳转详情页或列表
    details(id, type) {
      if (type == "goods") {
        this.$router.push({ name: "Details", query: { id: id, shareid: this.shareid, merchid: this.merchid, _t: 3 } });
      }else if(type == "category"){
        this.$router.push({ name: "List", query: { groupid: id, shareid: this.shareid, merchid: this.merchid } });
      }
    },
    // 跳转到列表页
    goSearch() {
      this.$router.push({ name: "Search", query: { shareid: this.shareid, merchid: this.merchid } });
    },
    // 跳转到全部商品列表页
    allGoods() {
      this.$router.push({ name: "List", query: { groupid: 0, shareid: this.shareid, merchid: this.merchid } })
    },
    // 请求广告图
    getadvimg() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/adv/get_list",
          _that.$qs.stringify({
            position: 3,
            merchid: _that.merchid,
            categoryid: _that.groupid
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.adsrcStr = res.data.data;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    // 点击图片跳转
    bannerLink(index) {
      console.log(index);
      if (index == 0) {
        this.$router.push({
          name: "BrandList",
          query: {
            shareid: this.shareid,
            merchid: this.merchid
          }
        })
      }
    },

    // 子商户绑定会员
    setmerchMber() {
      let _that = null;
      _that = this;
      if (localStorage.getItem("DK_ISLOGIN") && this.merchid > 0) {
        _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID")
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/merch_adduser",
            _that.$qs.stringify({
              merchid: _that.merchid
            })
          )
          .then(res => {
            console.log(res);
          })
          .catch(error => {
            console.log(error);
          })
      }
    },
  }
};

</script>

<style lang="less">
.about {
  text-align: left;
  min-height: 100%;
  background-color: #fff;
  overflow: hidden;
  padding: 0;
  .van-search {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 2;
    .van-cell__value {
      flex: 1;
    }
  }
  .van-search__content {
    background: #F1F2F7;
    border-radius: 34px;
    padding-left: 10px;
  }
  .about_body {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    margin-top: 10px;
    .content {
      overflow-x: hidden;
      overflow-y: scroll;
      flex: 1;
      background: #fff;
      // 广告位
      .category_ad {
        padding: 0 10px;
        overflow: hidden;
        .van-image {
          display: block;
          border-radius: 2px;
          overflow: hidden;
        }
      }
      .van-cell__title, .van-cell__value {
        flex: 1;
      }
      .van-cell__right-icon {
        margin-left: 0;
      }
      // 二级三级分类列表
      .content_list {
        overflow: hidden;
        padding-bottom: 50px;
        height: auto;
        .van-cell{
          padding: 10px;
          font-size: 12;
        }
        .van-sidebar-item {
          background-color: #f6f6f6;
        }
        .van-sidebar-item--select {
          font-weight: bold;
        }
        .van-grid-item__icon-wrapper {
          width: 100%;
          text-align: center;
          .van-image__error {
            width: 100%;
          }
          .van-image {
            overflow: hidden;
          }
        }
        .van-grid-item__text {
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 90%;
          color: #333;
          line-height: 20px;
        }
        .van-grid-item__content {
          padding: 0 10px 14px;
        }
      } 
    }
  }
}
</style>
